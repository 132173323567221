import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

const Logs = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}metadata`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TableContainer className='log-container' component={Paper} style={{ height: '100vh', width: '100vw' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className='log-header'>Fecha</TableCell>
            <TableCell className='log-header'>Hora</TableCell>
            <TableCell className='log-header'>Participantes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            const dateTimeString = item.created_at;
            const fecha = dateTimeString.slice(0, 10);            
            const hora = dateTimeString.slice(11, 19);
            return(
              <TableRow key={index}>
              <TableCell className='log-row'>{fecha}</TableCell>
              <TableCell className='log-row'>{hora}</TableCell>
              <TableCell className='log-row'>{item.people_count}</TableCell>
            </TableRow>
            );            
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Logs;
